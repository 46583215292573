
import Vue from "vue"
import Locale from "@/components/Locale.vue"
import PermissionList from "@/components/PermissionList.vue"
import Theme from "@/components/Theme.vue"
import Logout from "@/components/Logout.vue"

export default Vue.extend({
  components: {
    Locale,
    PermissionList,
    Theme,
    Logout
  },
})
