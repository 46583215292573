
import Vue, { VueConstructor } from "vue"
import permissionMixin from "@/mixins/PermissionMixin.vue"

export default (Vue as VueConstructor<Vue & InstanceType<typeof permissionMixin>>).extend({
  name: "PermissionMixin",
  mixins: [permissionMixin],
  computed: {
    availablePermissions() { // all permissions from local file, except "app-admin" one
      return Object.keys(this.$vuetify.lang.locales.en.permissions_names).filter((permission) => { return permission !== 'app-admin'})
    }
  }
})
