
import Vue from "vue"
import { mutations } from "@/plugins/state"

export default Vue.extend({
  methods: {
    logout() {
      mutations.logout()
      this.$router.push("/login")
    },
  },
})
