
import Vue from "vue"
import { state, mutations } from "@/plugins/state"

export default Vue.extend({
  computed: { 
    theme: {
      get: () => {
        return state.theme
      },
      set: (theme) => {
        mutations.setTheme(theme)
      }
    }
  },
  methods: {
    changeTheme() {
      this.theme = !this.theme
    }
  },
  watch: {
    theme: function(darkTheme) {
      this.$vuetify.theme.dark = darkTheme
      mutations.setTheme(darkTheme)
    }
  },
})
